import React from 'react';
import styled from 'styled-components';

const Holder = styled.div`
  width: 100%;
  max-width: 30rem;
  text-align: left;
  margin-top: 2rem;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  &:first-child {
    border-top: 1px solid;
  }
`;

const Cell = styled.small`
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid;
  border-right: 1px solid;
  &:first-child {
    border-left: 1px solid;
  }
`;

function SizeGuide() {
  return (
    <Holder>
      <Row>
        <Cell><b>SIZE GUIDE</b></Cell>
        <Cell><b>2</b></Cell>
        <Cell><b>4</b></Cell>
        <Cell><b>6</b></Cell>
        <Cell><b>8</b></Cell>
      </Row>
      <Row>
        <Cell><b>Body Width (cm)</b></Cell>
        <Cell>31</Cell>
        <Cell>34</Cell>
        <Cell>37</Cell>
        <Cell>39.5</Cell>
      </Row>
      <Row>
        <Cell><b>Body Length (cm)</b></Cell>
        <Cell>42</Cell>
        <Cell>46</Cell>
        <Cell>50</Cell>
        <Cell>54</Cell>
      </Row>
    </Holder>
  )
}

export default SizeGuide;
