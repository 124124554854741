import React from 'react';
import { graphql } from 'gatsby';
import Image from '../components/atoms/Image';
import Seo from '../components/molecules/Seo';
import styled from 'styled-components';
import ProductGroup from '../components/organisms/ProductGroup';
import SizeGuide from '../components/molecules/SizeGuide';
import {GatsbyImage} from "gatsby-plugin-image";
import MarkdownConverter from "../components/atoms/MarkdownConverter";
import {realTitles} from "../utils/helpers";

const Holder = styled.article`
  text-align: justify;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin-bottom: 2rem;
  @media( ${props => props.theme.breakpoints.md} ) { 
    grid-template-columns: 1fr 1fr;
    align-items: start;
    grid-auto-flow: dense;
  }
`;

const Content = styled.div`
  @media( ${props => props.theme.breakpoints.md} ) { 
    grid-row: span 2;
  }
  @media( ${props => props.theme.breakpoints.lg} ) { 
    grid-row: span 1;
  }
  h1 {
    margin-bottom: 0;
  }
  button {
    margin: 1rem 0 2rem 0;
  }
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
  .soldOut { ${props => props.theme.fluidType( 1 )}; }
`;

function Product( { data } ) {
  const {
    title,
    url,
    price,
    description,
    id,
    taxonomy_path,
    childrenEtsyListingImage
  } = data.etsyListing;

  const images = childrenEtsyListingImage;

  return (
    <>
      <Seo title={realTitles[id] || title}/>
      <Holder>
        <GatsbyImage
          alt={realTitles[id] || title}
          image={images[0].childFile.childImageSharp.gatsbyImageData}/>
        <Content>
          <h1>{realTitles[id] || title}</h1>
          <p className="h1">${price}</p>
          <MarkdownConverter content={description}/>
          <a className="button" href={url} target="_blank" rel="noopener noreferrer">View on etsy</a>
          {!taxonomy_path.includes("Books") && <SizeGuide/>}
        </Content>
        {images.map( ( image, i ) =>
          i > 0 && <GatsbyImage
            key={i}
            alt={realTitles[id] || title}
            image={image.childFile.childImageSharp.gatsbyImageData}/>
        )}
      </Holder>
      <ProductGroup products={data.allEtsyListing.nodes} title="More magic stuff"/>
    </>
  )
}

export default Product;

export const pageQuery = graphql`
    query($id: String!) {
        etsyListing(id: { eq: $id }) {
            title
            url
            currency_code
            price
            description
            id
            featured_rank
            taxonomy_path
            childrenEtsyListingImage {
              childFile {
                childImageSharp {
                  gatsbyImageData( 
                    breakpoints: 1000
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
              }
            }
        }
        allEtsyListing(
          sort: {fields: featured_rank, order: ASC}
        ) {
          nodes {
            title
            url
            currency_code
            price
            description
            id
            featured_rank
            taxonomy_path
            childrenEtsyListingImage {
              childFile {
                childImageSharp {
                  gatsbyImageData( 
                    breakpoints: 1000
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
    }
`;
